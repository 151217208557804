export function formatUrl(base: string, query: Record<string, any> = {}) {
  const queryFormatted = Object.entries(query)
    .filter(([_, value]) => {
      // If the value is an array, make sure its not empty
      if (Array.isArray(value)) {
        return value.length > 0
      }

      return value !== '' && typeof value !== 'undefined'
    })
    .flatMap(([key, value]) => {
      if (Array.isArray(value)) {
        // For arrays, duplicate the key multiple times, such that { key: [1,2] }
        // will serialize to ?key=1&key=2
        return value.map((value) => `${key}=${value}`)
      }

      return [`${key}=${value}`]
    })
    .join('&')

  return queryFormatted ? `${base}?${queryFormatted}` : base
}
